import './ContractNumbersStep.less';

import * as R from 'ramda';
import React from 'react';
import { connect } from 'react-redux';
import { graphql, QueryRenderer } from 'react-relay';
import { FieldTitle } from '../../../uikit/UIKit';
import environment from '../../../graphql/Environment';
import withUser from '../../../withUser';
import {
  propositionGoPreviousStep,
  propositionSendForSignature,
  propositionSaveContractNumbers,
  propositionSetContractNumbersAreEdited,
  propositionResetSendForSignatureStatus,
  propositionMakeDuplicata,
  propositionResetMakeDuplicataStatus, propositionGoNextStep
} from '../../../reduxActions/proposition';
import withConfig from '../../../withConfig';
import useContractNumbers from './contractNumbersStep/useContractNumbers';
import NextButtons from './contractNumbersStep/NextButtons';
import ContractNumbersByOfferType from './contractNumbersStep/ContractNumbersByOfferType';
import DocumentsConversionFailed from './contractNumbersStep/DocumentsConversionFailed';
import ContractNumbers from './contractNumbersStep/ContractNumbers';
import Error from '../../../components/Error';
import { formatters } from '@fasstech/spid-front';
import allowElectronicSignature from './helpers/allowElectronicSignature';
import getAmendmentInfos from '../../../lib/getAmendmentInfos';

const ContractNumbersStepQuery = graphql`
  query ContractNumbersStepQuery($id: ID!) {
    proposition(id: $id) {
      id
      numberOfContracts
      contractNumbers
      declarativeAnnexe
      outstandingAnnexe
      contractDescription {
        C {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        NC {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        AM {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        TOUS {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
        APP {
          bases {
            baseId
            options {
              optionId
            }
          }
        }
      }
      staff {
        C {
          isSelected
        }
        NC {
          isSelected
        }
        AM {
          isSelected
        }
        APP {
          isSelected
        }
        TOUS {
          isSelected
        }
      }
      amendment {
        isAmendment
        formerNumberOfContracts
        formerColleges
        formerContractDescription {
          C {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          NC {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          AM {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          TOUS {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          APP {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
        }
      }
      health {
        numberOfContracts
        contractNumbers
        amendment {
          isAmendment
          formerNumberOfContracts
          formerColleges
          formerContractDescription {
            C {
              bases {
                baseId
                options {
                  optionId
                }
              }
            }
            NC {
              bases {
                baseId
                options {
                  optionId
                }
              }
            }
            AM {
              bases {
                baseId
                options {
                  optionId
                }
              }
            }
            TOUS {
              bases {
                baseId
                options {
                  optionId
                }
              }
            }
            APP {
              bases {
                baseId
                options {
                  optionId
                }
              }
            }
          }
        }
        contractDescription {
          C {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          NC {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          AM {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          TOUS {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
          APP {
            bases {
              baseId
              options {
                optionId
              }
            }
          }
        }
        staff {
          C {
            isSelected
          }
          NC {
            isSelected
          }
          AM {
            isSelected
          }
          APP {
            isSelected
          }
          TOUS {
            isSelected
          }
        }
      }
    }
  }
`;

const SignatureError = ({ error }) => {
  const message = R.prop(error, {
    CONTRACT_NUMBERS_ALREADY_USED: 'L\'un des numéros de contrats est déjà utilisé.'
  });

  if (R.isNil(message)) {
    return <Error/>;
  }

  return <div>
    {message}
  </div>;
};

const ContractNumbersStep = ({
  ccnId,
  contractDescription,
  contractNumbersAreDefined,
  contractNumbers,
  duplicata,
  contractNumbersAreEdited,
  hasParitarism,
  makeDuplicata,
  relatedContract,
  resetMakeDuplicataStatus,
  resetSendForSignatureStatus,
  saveContractNumbers,
  sendElectronicMode,
  sendManualMode,
  onEdit,
  ccn,
  user,
  userRole,
  config,
  offerType,
  offerTypes,
  onNext,
  turnovers,
  proposition,
  displayContractNumbersByOfferType,
  withOutstandingAnnexe,
  withDeclarativeAnnexe,
  allowElectronicSignature,
  hasLessThan20Employees,
  isHealthAmendment,
  courseIsAmendment
}) => {
  const {
    numberOfContracts,
    staff,
    amendment
  } = offerType === 'SANTE' ? R.propOr({}, 'health', proposition) : proposition;

  const {
    isCommercialNetwork,
    contractNumbersAutoGeneration,
    allowSignatureDelay,
    turnoversAreValid,
    hideTurnovers
  } = useContractNumbers({
    resetSendForSignatureStatus,
    resetMakeDuplicataStatus,
    onEdit,
    contractNumbers,
    user,
    userRole,
    duplicata,
    config,
    amendment,
    turnovers,
    offerType,
    staff,
    ccn,
    contractDescription,
    isHealthAmendment,
    courseIsAmendment
  });

  const _contractNumbersAreDefined = contractNumbersAreDefined && R.length(contractNumbers) === numberOfContracts;

  const commonProps = {
    edited: contractNumbersAreEdited,
    onEdit,
    onSave: saveContractNumbers,
    contractNumbersAreDefined: _contractNumbersAreDefined,
    duplicata,
    relatedContract,
    ccnId,
    ccn,
    contractNumbersAutoGeneration,
    userRole,
    turnoversAreValid,
    hideTurnovers,
    isHealthAmendment
  };

  return <div>
    {isCommercialNetwork && !contractNumbersAutoGeneration && (
      <div className="contract-number-arpej-btn-wrapper">
        <a href={config.arpejLink} className="uikit-btn uikit-btn-default uikit-btn-small" target="_blank" rel="noopener noreferrer">{'Numéroter contrat'}</a>
      </div>
    )}

    {contractNumbersAutoGeneration && !displayContractNumbersByOfferType && <div style={{ fontWeight: 'bold', fontSize: '1.5em', marginBottom: '20px' }}>Numéro(s) de contrat(s) associé(s) à votre proposition</div>}

    {!displayContractNumbersByOfferType && <ContractNumbers
      value={numberOfContracts}
      amendment={R.pathOr({}, offerType === 'SANTE' ? ['health', 'amendment'] : ['amendment'], proposition)}
      contractNumbers={contractNumbers}
      contractDescription={contractDescription}
      offerType={offerType}
      staff={staff}
      {...commonProps}
    />}

    {displayContractNumbersByOfferType && <div>
      <ContractNumbersByOfferType
        offerTypeLabel="Prévoyance"
        offerType="PREVOYANCE"
        contractNumbers={R.prop('contractNumbers', proposition)}
        numberOfContracts={R.prop('numberOfContracts', proposition)}
        amendment={R.propOr({}, 'amendment', proposition)}
        contractDescription={formatters.formatContractDescriptionToSelection(R.propOr({}, 'contractDescription', proposition))}
        staff={R.propOr({}, 'staff', proposition)}
        commonProps={commonProps}
      />

      <ContractNumbersByOfferType
        offerTypeLabel="Santé"
        offerType="SANTE"
        contractNumbers={R.path(['health', 'contractNumbers'], proposition)}
        numberOfContracts={R.path(['health', 'numberOfContracts'], proposition)}
        amendment={R.pathOr({}, ['health', 'amendment'], proposition)}
        contractDescription={formatters.formatContractDescriptionToSelection(R.pathOr({}, ['health', 'contractDescription'], proposition))}
        staff={R.pathOr({}, ['health', 'staff'], proposition)}
        commonProps={commonProps}
      />
    </div>}

    {hasParitarism && <span>Le paritarisme n’est pas contractualisé auprès du client. Un numéro de contrat doit lui être attribué pour l’appel des cotisations.</span>}
    {withOutstandingAnnexe && <>
      {hasLessThan20Employees
        ? <div className="text-red-500 my-8">
          Proposition avec sinistres en cours envoyée en signature Papier.<br/>
          La mise en gestion du ou des contrats se fait obligatoirement à partir d’une fiche ISIMEG.<br/>
          Le kit contractuel (dont annexe déclarative) est à compléter et signer manuellement par l’Entreprise.<br/>
          A la réception des documents signés, il sera nécessaire de demander la mise en gestion dans SPID pour
          actualiser la proposition.
        </div>
        : <div className="my-8">En cas de majoration ET/OU d’encours tarifés, il est nécessaire de créer une fiche ISIMEG pour la mise en gestion du ou des contrat(s).</div>
      }
    </>}

    {withDeclarativeAnnexe && !hasLessThan20Employees &&
    <div className="my-8">En cas d’encours mutualisé, il est nécessaire de créer une fiche ISIMEG pour la mise en gestion du ou des contrat(s).</div>
    }

    {!contractNumbersAreEdited && _contractNumbersAreDefined && (
      <NextButtons
        duplicata={duplicata}
        makeDuplicata={makeDuplicata}
        sendManualMode={sendManualMode}
        sendElectronicMode={sendElectronicMode}
        allowSignatureDelay={allowSignatureDelay}
        allowElectronicSignature={allowElectronicSignature}
        offerType={offerType}
        offerTypes={offerTypes}
        onNext={onNext}
        disabled={!turnoversAreValid(contractNumbers)}
      />
    )}
  </div>;
};

const ContractNumbersStepWrapper = ({
  ccnId,
  contractDescription,
  contractNumbersAreDefined,
  contractNumbers,
  duplicata,
  contractNumbersAreEdited,
  goPreviousStep,
  hasParitarism,
  makeDuplicata,
  makeDuplicataError,
  makeDuplicataStatus,
  propositionId,
  propositionStatus,
  relatedContract,
  resetMakeDuplicataStatus,
  resetSendForSignatureStatus,
  saveContractNumbers,
  sendElectronicMode,
  sendForSignatureError,
  sendForSignatureStatus,
  sendManualMode,
  onEdit,
  originContractId,
  ccn,
  user,
  userRole,
  config,
  offerType,
  offerTypes,
  onNext,
  turnovers,
  withOutstandingAnnexe,
  withDeclarativeAnnexe,
  allowElectronicSignature,
  hasLessThan20Employees,
  isHealthAmendment,
  courseIsAmendment
}) => {
  const displayContractNumbersByOfferType = offerType === 'PREVOYANCE' && R.length(offerTypes) > 1;

  if (sendForSignatureError || makeDuplicataError) {
    return <SignatureError error={sendForSignatureError}/>;
  } else if (R.isNil(sendForSignatureStatus) && R.isNil(makeDuplicataStatus)) {
    return <div>
      <FieldTitle
        text={displayContractNumbersByOfferType ? 'Récapitulatif des contrats' : `Numéro(s) de contrat${duplicata ? '' : ' et signature'}` }
        onBack={R.includes(propositionStatus, ['unhold', 'ready']) ? undefined : goPreviousStep}
      />

      <QueryRenderer
        environment={environment}
        query={ContractNumbersStepQuery}
        variables={{
          id: propositionId
        }}
        render={({ error, props }) => {
          if (error) {
            return <Error/>;
          } else if (!props) return null;

          const { proposition } = props;

          if (!R.isNil(proposition) && (proposition.status === 'sentForSignature' || proposition.status === 'sentForSignatureDelayed')) {
            return <div>
              <h3>{'Cette proposition n\'est plus disponible.'}</h3>
            </div>;
          }

          return <ContractNumbersStep
            ccnId={ccnId}
            contractDescription={contractDescription}
            contractNumbersAreDefined={contractNumbersAreDefined}
            contractNumbers={contractNumbers}
            duplicata={duplicata}
            contractNumbersAreEdited={contractNumbersAreEdited}
            hasParitarism={hasParitarism}
            makeDuplicata={makeDuplicata}
            relatedContract={relatedContract}
            resetMakeDuplicataStatus={resetMakeDuplicataStatus}
            resetSendForSignatureStatus={resetSendForSignatureStatus}
            saveContractNumbers={saveContractNumbers}
            sendElectronicMode={sendElectronicMode}
            sendManualMode={sendManualMode}
            onEdit={onEdit}
            ccn={ccn}
            user={user}
            userRole={userRole}
            config={config}
            offerType={offerType}
            offerTypes={offerTypes}
            onNext={onNext}
            turnovers={turnovers}
            proposition={proposition}
            displayContractNumbersByOfferType={displayContractNumbersByOfferType}
            withOutstandingAnnexe={withOutstandingAnnexe}
            withDeclarativeAnnexe={withDeclarativeAnnexe}
            allowElectronicSignature={allowElectronicSignature}
            hasLessThan20Employees={hasLessThan20Employees}
            isHealthAmendment={isHealthAmendment}
            courseIsAmendment={courseIsAmendment}
          />;
        }}
      />
    </div>;
  } else if (sendForSignatureStatus === 'conversion_failed' || makeDuplicataStatus === 'conversion_failed') {
    return <DocumentsConversionFailed
      duplicata={duplicata}
      resetMakeDuplicataStatus={resetMakeDuplicataStatus}
      resetSendForSignatureStatus={resetSendForSignatureStatus}
    />;
  } else if ((!duplicata && sendForSignatureStatus !== 'email_sent_to_user') || (duplicata && makeDuplicataStatus !== 'email_sent_to_user')) {
    return (
      <div>
        <FieldTitle
          text={ duplicata ? 'Duplicata en cours d\'envoi' : 'Documents envoyés pour signature' }
        />
        {'Nous préparons les documents, veuillez patientez quelques secondes.'}
      </div>
    );
  } else {
    return (
      <div>
        <FieldTitle
          text={ duplicata ? 'Le duplicata a été envoyé' : 'Documents envoyés pour signature' }
        />
        {R.isNil(originContractId) ?
          <span>{'Le mail a été envoyé, vous devriez le recevoir d\'ici quelques secondes.'}</span>
          :
          <span>{'Le mail a été envoyé et la souscription dupliquée est close.'}</span>
        }
      </div>
    );
  }
};

export default withConfig(withUser(connect(
  ({ proposition }, { userRoleIs }) => {
    const currentOfferType = R.pathOr('PREVOYANCE', ['data', 'currentOfferType'])(proposition);

    return ({
      ccn: R.pathOr('', ['data', 'ccn'])(proposition),
      ccnId: R.pathOr('', ['data', 'ccn', 'id'])(proposition),
      duplicata: R.pathOr(false, ['data', 'duplicata'])(proposition),
      makeDuplicataStatus: R.path(['makeDuplicataStatus', 'value'], proposition),
      makeDuplicataError: R.path(['makeDuplicataStatus', 'error'], proposition),
      userRole: R.path(['userRole'], proposition),
      propositionId: R.path(['data', 'propositionId'], proposition),
      contractNumbersAreDefined: R.compose(
        R.not,
        R.either(
          R.reduce((acc, contractNumber) => acc || R.isEmpty(contractNumber), false),
          R.isEmpty
        ),
        R.pathOr([], currentOfferType === 'SANTE' ? ['data', 'health', 'contractNumbers'] : ['data', 'contractNumbers'])
      )(proposition),
      contractNumbers: R.path(currentOfferType === 'SANTE' ? ['data', 'health', 'contractNumbers'] : ['data', 'contractNumbers'])(proposition),
      turnovers: R.pathOr([], currentOfferType === 'SANTE' ? ['data', 'health', 'turnovers'] : ['data', 'turnovers'])(proposition),
      contractNumbersAreEdited: R.path(['contractNumbersAreEdited'])(proposition),
      sendForSignatureStatus: R.path(['sendForSignatureStatus', 'value'], proposition),
      sendForSignatureError: R.path(['sendForSignatureStatus', 'error'], proposition),
      propositionStatus: R.path(['data', 'status'], proposition),
      hasParitarism: R.pathOr(false, ['data', 'ccn', 'hasParitarism'])(proposition),
      relatedContract: R.pathOr({}, ['data', 'relatedContract'])(proposition),
      contractDescription: R.pathOr({}, currentOfferType === 'SANTE' ? ['data', 'health', 'contractDescription'] : ['data', 'contractDescription'])(proposition),
      originContractId: R.path(['data', 'originContractId'])(proposition),
      offerType: currentOfferType,
      offerTypes: R.pathOr([], ['data', 'offerTypes'], proposition),
      withOutstandingAnnexe: R.pathEq(['data', 'outstandingAnnexe'], 'with', proposition),
      withDeclarativeAnnexe: R.pathEq(['data', 'declarativeAnnexe'], 'with', proposition),
      allowElectronicSignature: allowElectronicSignature({ userRoleIs, proposition }),
      hasLessThan20Employees: R.pathOr(false, ['data', 'additionalInformations', 'PREVOYANCE', 'hasLessThan20Employees'], proposition),
      ...getAmendmentInfos(proposition.data)
    });
  },
  dispatch => ({
    resetSendForSignatureStatus: () => dispatch(propositionResetSendForSignatureStatus({})),
    goPreviousStep: () => dispatch(propositionGoPreviousStep()),
    sendManualMode: () => dispatch(propositionSendForSignature('manual')),
    sendElectronicMode: mode => dispatch(propositionSendForSignature('electronic', mode)),
    makeDuplicata: () => dispatch(propositionMakeDuplicata()),
    onEdit: (b) => dispatch(propositionSetContractNumbersAreEdited(b)),
    resetMakeDuplicataStatus: () => dispatch(propositionResetMakeDuplicataStatus()),
    saveContractNumbers: (contractNumbers, offerType) => dispatch(propositionSaveContractNumbers(contractNumbers, offerType)),
    onNext: () => dispatch(propositionGoNextStep())
  })
)(ContractNumbersStepWrapper)));
